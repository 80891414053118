<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to dashboard"
              @click="$router.push({name: 'researcher.dashboard'})"
            ><i data-feather="chevron-left" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">Access data</a>
                </h3>
              </div>
              <div class="card-body">
                <p>Email: <strong>{{ loggedUser.email }}</strong></p>
                <span class="dis-ib">
                  <a
                    :ref="`collapseEditAccess`"
                    v-b-toggle="`collapseEditAccess`"
                    class="btn btn-icon btn-primary"
                  >
                    Change password
                  </a>
                </span>
                <b-collapse
                  :id="`collapseEditAccess`"
                >
                  <div class="row my-1">
                    <div class="col-4">
                      <label
                        for="nameseniorcall"
                        class="form-label"
                      >{{ labels['access_data.current_password'] }}</label>
                      <div class="input-group">
                        <input
                          v-model="currentPassword"
                          :type="currentType"
                          class="form-control"
                          name="nameseniorcall"
                        >
                        <span
                          class="input-group-text pe-auto"
                          :class="{'d-none' : currentType === 'text'}"
                          @click="changeType('currentType')"
                        ><i
                          class="me-50"
                          data-feather="eye"
                        /></span>
                        <span
                          class="input-group-text pe-auto"
                          :class="{'d-none' : currentType === 'password'}"
                          @click="changeType('currentType')"
                        >
                          <i
                            class="me-50"
                            data-feather="eye-off"
                          /></span>
                      </div>
                    </div>
                    <div class="col-4">
                      <label
                        for="nameseniorcall"
                        class="form-label"
                      >{{ labels['access_data.new_password'] }}</label>
                      <div class="input-group">
                        <input
                          v-model="newPassword"
                          :type="passwordType"
                          class="form-control"
                          name="nameseniorcall"
                        >
                        <span
                          class="input-group-text pe-auto"
                          :class="{'d-none' : passwordType === 'text'}"
                          @click="changeType('passwordType')"
                        ><i
                          class="me-50"
                          data-feather="eye"
                        /></span>
                        <span
                          class="input-group-text pe-auto"
                          :class="{'d-none' : passwordType === 'password'}"
                          @click="changeType('passwordType')"
                        >
                          <i
                            class="me-50"
                            data-feather="eye-off"
                          /></span>
                      </div>
                    </div>
                    <div class="col-4">
                      <label
                        for="nameseniorcall"
                        class="form-label"
                      >{{ labels['access_data.confirm_password'] }}</label>
                      <div class="input-group">
                        <input
                          v-model="confirm"
                          :type="passwordNewType"
                          class="form-control"
                          name="nameseniorcall"
                        >
                        <span
                          class="input-group-text pe-auto"
                          :class="{'d-none' : passwordNewType === 'text'}"
                          @click="changeType('passwordNewType')"
                        ><i
                          class="me-50"
                          data-feather="eye"
                        /></span>
                        <span
                          class="input-group-text pe-auto"
                          :class="{'d-none' : passwordNewType === 'password'}"
                          @click="changeType('passwordNewType')"
                        >
                          <i
                            class="me-50"
                            data-feather="eye-off"
                          /></span>
                      </div>
                    </div>
                  </div>
                  <div id="message">
                    <h3>Password must contain the following:</h3>
                    <p
                      class="invalid"
                      :class="lowerCase ? 'text-success' : 'text-danger'"
                    >
                      <strong :class="{'d-none': !lowerCase}">
                        <i
                          data-feather="check"
                          class="text-success me-1"
                        />
                      </strong>
                      <strong :class="{'d-none': lowerCase}">
                        <i
                          data-feather="x"
                          class="text-danger me-1"
                        />
                      </strong>
                      A <b>lowercase</b> letter
                    </p>
                    <p
                      class="invalid"
                      :class="upperCase ? 'text-success' : 'text-danger'"
                    >
                      <strong :class="{'d-none': !upperCase}">
                        <i
                          data-feather="check"
                          class="text-success me-1"
                        />
                      </strong>
                      <strong :class="{'d-none': upperCase}">
                        <i
                          data-feather="x"
                          class="text-danger me-1"
                        />
                      </strong>
                      A <b>capital (uppercase)</b> letter
                    </p>
                    <p
                      class="invalid"
                      :class="specialChar ? 'text-success' : 'text-danger'"
                    >
                      <strong :class="{'d-none': !specialChar}">
                        <i
                          data-feather="check"
                          class="text-success me-1"
                        />
                      </strong>
                      <strong :class="{'d-none': specialChar}">
                        <i
                          data-feather="x"
                          class="text-danger me-1"
                        />
                      </strong>
                      A <b>special</b> character
                    </p>
                    <p
                      class="invalid"
                      :class="number ? 'text-success' : 'text-danger'"
                    >
                      <strong :class="{'d-none': !number}">
                        <i
                          data-feather="check"
                          class="text-success me-1"
                        />
                      </strong>
                      <strong :class="{'d-none': number}">
                        <i
                          data-feather="x"
                          class="text-danger me-1"
                        />
                      </strong>
                      A <b>number</b>
                    </p>
                    <p
                      class="invalid"
                      :class="length > 7 ? 'text-success' : 'text-danger'"
                    >
                      <strong :class="{'d-none': length <= 7}">
                        <i
                          data-feather="check"
                          class="text-success me-1"
                        />
                      </strong>
                      <strong :class="{'d-none': length > 7}">
                        <i
                          data-feather="x"
                          class="text-danger me-1"
                        />
                      </strong>
                      Minimum <b>8 characters</b>
                    </p>
                    <p
                      class="invalid"
                      :class="newPassword === confirm ? 'text-success' : 'text-danger'"
                    >
                      <strong :class="{'d-none': newPassword !== confirm}">
                        <i
                          data-feather="check"
                          class="text-success me-1"
                        />
                      </strong>
                      <strong :class="{'d-none': newPassword === confirm}">
                        <i
                          data-feather="x"
                          class="text-danger me-1"
                        />
                      </strong>
                      Password <b>matching</b>
                    </p>
                    <p
                      class="invalid"
                      :class="currentPassword ? 'text-success' : 'text-danger'"
                    >
                      <strong :class="{'d-none': !currentPassword}">
                        <i
                          data-feather="check"
                          class="text-success me-1"
                        />
                      </strong>
                      <strong :class="{'d-none': currentPassword}">
                        <i
                          data-feather="x"
                          class="text-danger me-1"
                        />
                      </strong>
                      Current password
                    </p>
                  </div>
                  <span class="dis-ib">
                    <a
                      class="btn btn-icon btn-success"
                      :class="!canChange ? 'd-none' : ''"
                      @click="canChange ? changePassword() : ''"
                    >
                      <div
                        v-if="loading"
                        class="loading custom-loading"
                      >
                        <div class="effect-1 effects" />
                        <div class="effect-2 effects" />
                        <div class="effect-3 effects" />
                      </div>
                      <span v-if="!loading">
                        Save new password
                      </span>
                    </a>
                  </span>
                  <span
                    v-if="status"
                    class="ms-1 p-25 alert alert-danger"
                  >
                    {{ status }}
                  </span>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'
import Vue from 'vue'

export default {
  components: {
    BCollapse,
  },
  data() {
    return {
      title: 'Edit intranet access',
      currentPassword: '',
      newPassword: '',
      confirm: null,
      loading: false,
      status: null,
      currentType: 'password',
      passwordType: 'password',
      passwordNewType: 'password',
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
      labels: 'sectionLabels/itemLabels',
    }),
    canChange() {
      return this.currentPassword && this.lowerCase && this.upperCase && this.number && this.specialChar && this.length > 7 && this.newPassword === this.confirm
    },
    specialChar() {
      return /[!@#$%^&*(),.?":{}|<>]/.test(this.newPassword)
    },
    lowerCase() {
      return /[a-z]/.test(this.newPassword)
    },
    upperCase() {
      return /[A-Z]/.test(this.newPassword)
    },
    number() {
      return /\d/.test(this.newPassword)
    },
    length() {
      return this.newPassword.length
    },
  },
  async mounted() {
    this.$store.dispatch('sectionLabels/fetchLabels', 'dashboard.intranet_access')
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 200)
  },
  methods: {
    async changePassword() {
      this.loading = true
      this.status = null

      const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users/change-password`, {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword,
        confirm: this.confirm,
      })

      if (resp.data === 0) {
        this.status = 'Current password not correct'
      } else {
        Vue.swal('Password changed successfully!', '', 'success')
        this.currentPassword = ''
        this.newPassword = ''
        this.confirm = null
        this.$refs.collapseEditAccess.click()
        await this.$store.dispatch('modals/checkPassword', { userId: this.loggedUser.id })
      }

      this.loading = false
    },
    changeType(type) {
      switch (type) {
        case 'currentType':
          if (this.currentType === 'password') this.currentType = 'text'
          else this.currentType = 'password'
          break
        case 'passwordType':
          if (this.passwordType === 'password') this.passwordType = 'text'
          else this.passwordType = 'password'
          break
        case 'passwordNewType':
          if (this.passwordNewType === 'password') this.passwordNewType = 'text'
          else this.passwordNewType = 'password'
          break
        default: break
      }
    },
  },
}
</script>
